import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import logoAv from "../../assets/logos/avantari.svg";
import linkedInNav from "../../assets/logos/linkedInNav.svg";
import twitterNav from "../../assets/logos/twitterNav.svg";
import GoToTop from "../GoToTop";
import "./style.css";
import { useHistory, NavLink } from "react-router-dom";

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 20% auto 20%;
`;

const GreetDesk = styled.div`
  height: 55px;
  position: relative;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  height: 100%;
`;

const MarginDiv = styled.div`
  margin-left: 5vw;
  margin-right: 5vw;
`;

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

function Header(props) {
  const history = useHistory();
  const [hamburgerMenu, sethamburgerMenu] = useState(true);
  const [hamburgerMenuClass, sethamburgerMenuClass] = useState("hamburger");
  const [sidenavOpenOverlayClass, setsidenavOpenOverlayClass] = useState(
    "setsidenavOpenOverlayNone"
  );

  const [showMenu, setShowMenu] = useState(true);
  const [showDropNav, setShowDropNav] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowDropNav(false));

  console.log(showDropNav);

  const handleNaveOpen = (e) => {
    e.preventDefault();
    sethamburgerMenu(!hamburgerMenu);
    if (hamburgerMenu === true) {
      sethamburgerMenuClass("hamburger");
      setsidenavOpenOverlayClass("setsidenavOpenOverlayNone");
    } else if (hamburgerMenu === false) {
      sethamburgerMenuClass("hamburgerOpen");
      setsidenavOpenOverlayClass("setsidenavOpenOverlay");
    }
  };

  const navigateContactHeader = (pathName) => {
    setTimeout(() => {
      history.push(pathName);
      window.scroll({
        top: 0,
        left: 0,
      });
      setShowMenu(true);
      sethamburgerMenu(false);
      sethamburgerMenuClass("hamburger");
      setsidenavOpenOverlayClass("setsidenavOpenOverlayNone");
    }, 500);
  };

  const navigateFooter = () => {
    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
    setShowMenu(true);
    sethamburgerMenu(false);
    sethamburgerMenuClass("hamburger");
    setsidenavOpenOverlayClass("setsidenavOpenOverlayNone");
  };

  return (
    <div className="mainDIv">
      <ContentWrapper>
        <MarginDiv>
          <div className="ipadMobNav">
            <div className={sidenavOpenOverlayClass}>
              <ul style={{ listStyle: `none` }} className="newBar">
                <li>
                  <p
                    style={{ cursor: `pointer` }}
                    className="liPara"
                    onClick={() => navigateContactHeader(`/`)}
                  >
                    Home
                  </p>
                </li>
                <li>
                  <p
                    style={{ cursor: `pointer` }}
                    className="liPara"
                    onClick={() => navigateContactHeader(`/about`)}
                  >
                    About
                  </p>
                </li>
                <li>
                  <p
                    style={{ cursor: `pointer` }}
                    className="liPara"
                    onClick={() => navigateContactHeader(`/project`)}
                  >
                    Projects
                  </p>
                </li>

                <li>
                  <p
                    style={{ cursor: `pointer` }}
                    className="liPara"
                    onClick={() => navigateContactHeader(`/services`)}
                  >
                    Services
                  </p>
                </li>

                <li>
                  <p
                    style={{ cursor: `pointer` }}
                    className="liPara"
                    onClick={navigateFooter}
                  >
                    Contact
                  </p>
                </li>
                <li>
                  <a
                    href="https://in.linkedin.com/company/avantari"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={linkedInNav}
                      alt=""
                      width=""
                      height=""
                      style={{ marginRight: `20px`, cursor: `pointer` }}
                    />
                  </a>
                  <a
                    href="https://twitter.com/avantaritech?lang=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={twitterNav}
                      alt=""
                      width=""
                      height=""
                      style={{ cursor: `pointer` }}
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="">
              <NavLink to="/">
                <img src={logoAv} className="logoAv" alt="" />
              </NavLink>
            </div>

            <div className="">
              {showMenu ? (
                <div className="menu-toggle">
                  <div
                    className={hamburgerMenuClass}
                    onClick={(e) => handleNaveOpen(e)}
                  ></div>
                </div>
              ) : (
                <div style={{ float: `right` }}></div>
              )}
            </div>
          </div>
          <div className="deskNav">
            <PageContainer>
              <GreetDesk>
                <NavLink to="/">
                  <img src={logoAv} className="logoAv" alt="" />
                </NavLink>
              </GreetDesk>
              <GreetDesk>
                <div className="deskdiv">
                  <ul>
                    <li id="rightDiv">
                      <NavLink
                        exact
                        to="/"
                        style={{ textDecoration: `none` }}
                        className="liPara"
                        activeClassName="activeHeader"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li id="rightDiv">
                      <NavLink
                        to="/about"
                        style={{ textDecoration: `none` }}
                        className="liPara"
                        activeClassName="activeHeader"
                      >
                        About
                      </NavLink>
                    </li>
                    <li id="rightDiv">
                      <NavLink
                        to="/project"
                        style={{ textDecoration: `none` }}
                        className="liPara"
                        activeClassName="activeHeader"
                      >
                        Projects
                      </NavLink>
                    </li>
                    <li id="rightDiv">
                      <NavLink
                        to="/services"
                        style={{ textDecoration: `none` }}
                        className="liPara"
                        activeClassName="activeHeader"
                      >
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <a
                        onClick={navigateFooter}
                        style={{ textDecoration: `none` }}
                        className="liPara"
                        activeClassName="activeHeader"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </GreetDesk>
              <GreetDesk>
                <div className="logoR">
                  <a
                    href="https://in.linkedin.com/company/avantari"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={linkedInNav}
                      alt=""
                      width=""
                      height=""
                      style={{ marginRight: `20px`, cursor: `pointer` }}
                    />
                  </a>
                  <a
                    href="https://twitter.com/avantaritech?lang=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={twitterNav}
                      alt=""
                      width=""
                      height=""
                      style={{ cursor: `pointer` }}
                    />
                  </a>
                </div>
              </GreetDesk>
            </PageContainer>
          </div>
        </MarginDiv>
      </ContentWrapper>
      <GoToTop />
    </div>
  );
}

export default Header;
